import MobileLanguageSwitcher from '@/components/LanguageSwitcher/Mobile/MobileLanguageSwitcher';
import { Typography, getColor } from '@/shared';
import useStorage from '@/shared/helpers/sessionStorage';
import { ColorContext } from '@/shared/providers/ColorContext';
import { useMicrocopy } from '@/shared/providers/Microcopy/MicrocopyHook';
import { ScrollDirection, useScrollDirection } from '@/shared/providers/scrollDir';
import { NavbarStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { Icon } from '@/shared/ui/Icon/Icon';
import clsx from 'clsx';
import Link from 'next/link';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from './MobileNavbar.module.scss';

type NavProps = {
    blok: NavbarStoryblok;
    pageType: string;
    progress?: number;
    modalRef?: React.RefObject<HTMLDivElement>;
};

const MobileNavbar: React.FC<NavProps> = ({ blok, pageType, modalRef }) => {
    const currentUrlArr = window.location.href.split('/');
    const langcode = currentUrlArr.indexOf('de') >= 0 ? 'de' : 'en';
    const { microcopy } = useMicrocopy();
    const [clicked, setClicked] = useState(false);
    const [isPage, setIsPage] = useState(pageType === 'page');
    const [is2ndNavOpen, setIs2ndNavOpen] = useState(false);
    const { getItem } = useStorage();
    const storageColor = getItem('color');
    const hexColor = useContext(ColorContext);
    const varColor = getColor(hexColor, true, pageType);
    const pageColor = storageColor ?? varColor;
    const i = { '--color': pageColor } as React.CSSProperties;
    const color = pageColor.substring(6, pageColor.length - 1) || 'orange';
    const menuRef = useRef<HTMLDivElement>(null);
    const scrollDir = useScrollDirection(modalRef);

    function handleNavigation() {
        document.documentElement.classList.remove('no-scroll');
        setClicked(false);
    }

    function handleOpen() {
        document.documentElement.classList.add('no-scroll');
        setClicked(true);
    }

    function getCurrent(link: any) {
        return color.includes(link.color);
    }

    const handleTouch = useCallback(
        (e: TouchEvent) => {
            if (menuRef.current && clicked && !menuRef.current.contains(e.target as Node)) {
                document.documentElement.classList.remove('no-scroll');
                setClicked(false);
            }
        },
        [clicked],
    );
    const handleClick = useCallback((e: any) => {
        const targetElement = e.target;
        const navButton = targetElement.getAttribute('data-attribute');
        if (navButton === 'chapter-nav' || (e.target.textContent && e.target.textContent === microcopy.chapters)) {
            setIs2ndNavOpen(true);
        } else {
            setIs2ndNavOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('touchstart', e => handleTouch(e), { passive: true });
        document.addEventListener('click', e => handleClick(e), { passive: true });
        return () => {
            document.removeEventListener('touchstart', e => handleTouch(e));
        };
    }, [clicked, handleClick, handleTouch]);

    if (is2ndNavOpen) return null;

    return (
        <div
            className={clsx(styles.nav, {
                [styles.scrollingDown]: scrollDir === ScrollDirection.down,
                [styles.expanded]: clicked,
                [styles.navlight]: !isPage,
            })}
        >
            {clicked ? (
                <div className={styles.text} ref={menuRef}>
                    {blok.content![0].link?.map((link) => {
                        const isCurrent = getCurrent(link);
                        return (
                            <Link
                                className={styles.row}
                                key={link._uid}
                                href={link.link?.story?.full_slug ?? '/'}
                                onClick={() => handleNavigation()}
                                style={i}
                            >
                                <Typography.P darkTheme={isPage} className={clsx(isCurrent && styles.currentRow)}>
                                    {link.name}
                                </Typography.P>
                                <Icon name={'chevron_left'} />
                            </Link>
                        );
                    })}
                    {blok.content![1].link?.map((link) => (
                        <Link
                            className={styles.row}
                            key={link._uid}
                            href={link.link?.story?.full_slug ?? ''}
                            onClick={() => handleNavigation()}
                        >
                            <Typography.P darkTheme={isPage}>{link.name}</Typography.P>
                            <Icon name={'chevron_left'} />
                        </Link>
                    ))}
                    <div className={styles.row}>
                        <MobileLanguageSwitcher curLangCode={langcode} curColor={i} isPage={isPage} />
                    </div>
                    <div
                        className={clsx(styles.close, clicked && styles.open)}
                        onClick={() => handleNavigation()}
                        onKeyDown={e => e.key === 'Enter' && handleNavigation()}
                        tabIndex={0}
                        role="button"
                    >
                        <Typography.P darkTheme={isPage}>{microcopy.close}</Typography.P>
                        <span className={styles.closeIcon}>
                            <Icon name={'close'} />
                        </span>
                    </div>
                </div>
            ) : (
                <div
                    className={clsx(styles.text, styles.burger)}
                    onClick={handleOpen}
                    tabIndex={0}
                    role="button"
                    aria-roledescription="navigation"
                >
                    <Typography.P className={styles.menu} darkTheme={isPage}>
                        {microcopy.menu}
                    </Typography.P>
                    <Icon name={'burger'} />
                </div>
            )}
        </div>
    );
};
export default MobileNavbar;
